

















































































































































import fileDownload from 'js-file-download'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import api from '../../../../../../api'
import Dispatching from '../../../../../../store/models/Dispatching'
import xlsx from 'xlsx'
import VAlert from '../../../../../../components/VAlert.vue'
import ReimbursementFile from '../../../../../../store/models/ReimbursementFile'
export default Vue.extend({
  components: { VAlert },
  data() {
    return {
      loading: false,
      isLoading: false,
      hovered: false,
      file: null,
      totalColumn: 6,
      invalidFileError: ''
    }
  },

  computed: {
    ...mapGetters('product', ['getCurrentProduct']),
    dispatching() {
      return Dispatching.find(this.$route.params.dispatchingId)
    },
    btnDisabledCheck(this: any) {
      return !!(this.invalidFileError === '' && this.file)
    }
  },

  methods: {
    goNext(this: any) {
      this.isLoading = true
      ReimbursementFile.api()
        .create({
          dispatchingId: this.dispatching.id,
          file: this.file
        })
        .then((res: any) => {
          this.isLoading = false
          this.$router.push({
            name: 'finances.refunds.consignations.validationReimbFile',
            params: {
              reimbursementFileId: res.response.data.id
            }
          })
        })
        .catch((err: any) => {
          this.isLoading = false
          console.log({ err })
        })
    },
    async generateFile(this: any) {
      const url = `/reimbursement-file/generate/${this.$route.params.dispatchingId}`
      api
        .request({
          method: 'GET',
          url,
          responseType: 'blob'
        })
        .then(response => {
          fileDownload(
            response.data,
            `${this.dispatching.reference}_reimbursement_file.xlsx`,
            response.headers['content-type']
          )
          return response
        })
    },
    reset(this: any) {
      this.file = null
    },
    onFileChanged(this: any, event: any) {
      this.invalidFileError = ''
      if (event.target.files.length > 0) {
        this.file = event.target.files[0]

        const fileReader = new FileReader()

        this.loading = true

        fileReader.addEventListener('load', (event: any) => {
          const data = event.target.result
          const workbook = xlsx.read(data, {
            type: 'binary',
            cellDates: true
          })
          /* Get first worksheet */
          const wsname = workbook.SheetNames[0]
          const ws = workbook.Sheets[wsname]
          /* Convert array of arrays */
          const rows = xlsx.utils
            .sheet_to_json(ws, { header: 1 })
            .filter((row: any) => row.length > 0)

          rows.shift()
          let preItems = []
          preItems = rows.map((row: any) => {
            ;(row as any).forEach((cell: any, index: number) => {
              if (cell instanceof Date) {
                cell.setHours(cell.getHours() + 1)
              }
              row[index] = cell
            })

            return row
          })

          if (preItems.length == 0)
            this.invalidFileError =
              'Le fichier ne contient aucune donnée. Veuillez charger le bon fichier.'
          if (
            preItems.length != 0 &&
            Object.keys(preItems[0]).length !== this.totalColumn
          ) {
            this.invalidFileError =
              'Le fichier entré ne respecte pas le format requis. Veuillez charger le bon fichier.'
          }
          this.loading = false
        })

        fileReader.readAsBinaryString(this.file)
      }

      event.target.value = ''
    }
  },
  async created(this: any) {
    if (!this.dispatching) {
      Dispatching.api().fetch()
    }
  }
})
